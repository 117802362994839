'use client';

import { AD_SIZES_HASH, ADVERTISEMENT_TEXT_PLACEHOLDER } from '../utils/constants';
import { getZoneImage } from '../utils/getZoneImage';
import { triggerURL } from '../utils/triggerURL';
import { AdType, ModalPlacementInterface } from '../utils/types';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { useEffect, useRef, useState } from 'react';

// Regular expressions to match style, and text (html)
const STYLE_REGEX = /<style>(.*?)<\/style>/s; // 's' flag enables the dot (.) to match newline characters
const AFTER_REGEX = /<\/style>([\s\S]*)/s;

// Function to extract matched substrings
const extractParts = (input: string): (string | undefined)[] => {
	if (!input) {
		return ['', '', ''];
	}

	const styleMatch = input.match(STYLE_REGEX);
	const htmlMatch = input.match(AFTER_REGEX);

	// Extracting matched substrings or returning empty string if not found
	const stylePart = styleMatch ? styleMatch[1] : '';
	const htmlPart = htmlMatch ? htmlMatch[1] : '';

	return [stylePart, htmlPart];
};

interface PTAdvertisementInfo {
	image?: ModalPlacementInterface;
	pageId: number;
	place: number;
	viewableURLCalled: boolean;
	eligibleURLCalled: boolean;
}

// This pt advertisement is supposed to show on all screen sizes, so there is no showFromWidth prop
export interface PTAdvertisementProps {
	className?: string;
	ipAddress?: string;
	pageId: number;
	place: number;
	zoneId: string;
	isProd?: boolean;
}

const PTAdvertisement = ({ className, ipAddress, pageId, place, zoneId, isProd }: PTAdvertisementProps) => {
	const adRef = useRef<HTMLDivElement>(null);

	const placementId = `${zoneId}_${pageId}_${place}`;

	const [adInfo, setAdInfo] = useState<PTAdvertisementInfo>({
		pageId: +pageId,
		place: +place,
		viewableURLCalled: false,
		eligibleURLCalled: false
	});

	const adSizeInfo = AD_SIZES_HASH[zoneId as AdType].size || '0x0';

	useEffect(() => {
		if (isProd) {
			const fetchImage = async () => {
				const currentUrl = location.protocol + '//' + location.host + location.pathname;
				const screenWidth = screen.width;
				const screenHeight = screen.height;
				const screenPixelRatio = window.devicePixelRatio;
				const userAgent = navigator.userAgent;

				const response = await getZoneImage({
					ipAddress: ipAddress,
					pageId: adInfo.pageId,
					place: adInfo.place,
					refferingURL: currentUrl,
					screenHeight: screenHeight,
					screenPixelRatio: screenPixelRatio,
					screenWidth: screenWidth,
					size: adSizeInfo,
					userAgent: userAgent,
					zoneId: zoneId
				});

				if (response.status === 200 && response.placement) {
					const fetchedImage: ModalPlacementInterface = response.placement;

					setAdInfo((prevAdInfo) => ({
						...prevAdInfo,
						image: fetchedImage,
						place: prevAdInfo.place + 1,
						viewableURLCalled: false,
						eligibleURLCalled: false
					}));
				}
			};

			if (!adInfo.image) {
				fetchImage();
			}
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isProd) {
			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (!adInfo.eligibleURLCalled) {
							if (adInfo.image?.eligible_url) {
								triggerURL(adInfo.image.eligible_url);
							}
							setAdInfo((prevAdInfo) => ({ ...prevAdInfo, eligibleURLCalled: true }));
						}

						// Check if 50% or more of the target element is visible
						if (entry.intersectionRatio >= 0.5 && !adInfo.viewableURLCalled) {
							if (adInfo.image?.viewable_url) {
								triggerURL(adInfo.image.viewable_url);
							}
							setAdInfo((prevAdInfo) => ({ ...prevAdInfo, viewableURLCalled: true }));
						}
					});
				},
				{ threshold: 0.5 } // Set threshold to 0.5 (50% visibility)
			);

			const adElement = adRef.current;

			if (adElement) {
				observer.observe(adElement);
			}

			return () => {
				if (adElement) {
					observer.unobserve(adElement);
				}
			};
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adInfo.image, adInfo.viewableURLCalled, adInfo.eligibleURLCalled]);

	if (isProd && !adInfo.image) {
		return null;
	}

	const classes = clsx(
		'!size-full min-[500px]:min-w-[224px] min-[500px]:max-w-[286px] rounded-xl',
		{
			'border border-solid bg-gray-400 text-white': !isProd
		},
		className
	);

	const inputString = adInfo.image?.body || '';

	// Extracting parts from the input string
	const [stylesPart, htmlPart] = extractParts(inputString);

	return (
		<div id={placementId} className={classes} ref={adRef}>
			<style>{stylesPart}</style>
			{isProd && adInfo.image ? (
				<>
					{adInfo.image.is_redirectable && htmlPart ? (
						<a
							href={adInfo.image.redirect_url}
							target={adInfo.image.target}
							rel="nofollow"
							aria-label={`${adInfo.image.alt_text} (opens in new tab)`}
						>
							{parse(htmlPart)}
						</a>
					) : htmlPart ? (
						<>{parse(htmlPart)}</>
					) : null}
					{adInfo.image.accupixel_url && <img src={adInfo.image.accupixel_url} alt="" className="max-w-full" />}
				</>
			) : (
				<div className="inset-0 flex !size-full min-[500px]:min-w-[224px] min-[500px]:max-w-[286px] min-h-[292px] max-h-[360px] items-center justify-center object-cover">
					{ADVERTISEMENT_TEXT_PLACEHOLDER}
				</div>
			)}
		</div>
	);
};

export default PTAdvertisement;
