'use client';

import { useQuery } from '@apollo/client';
import { faArrowLeft, faArrowRight, faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import FavoritePPATournamentCard from '@/tournament/components/cards/FavoritePPATournamentCard';
import { PPA_TOURNAMENT_QUERY } from '@/tournament/lib/ppa-tournament.query';

interface PPATourneysCarouselProps {
	isHomepage?: boolean;
}

export const PPATourneysCarousel = ({ isHomepage }: PPATourneysCarouselProps) => {
	const { data, loading } = useQuery(PPA_TOURNAMENT_QUERY, {
		variables: {
			page: 0
			// requesterUuid: session?.user?.uuid || ''
		},
		fetchPolicy: 'cache-and-network'
	});

	const containerRef = React.useRef<HTMLDivElement>(null);

	const scroll = React.useCallback((direction: 'left' | 'right') => {
		const div = containerRef.current;
		if (div) {
			const position = direction === 'right' ? div.scrollLeft + 250 : div.scrollLeft - 250;
			const maxScrollLeft = div.scrollWidth - div.clientWidth;
			const left = direction === 'right' ? (position < maxScrollLeft ? position : maxScrollLeft) : position < 0 ? 0 : position;
			div.scrollTo({ left, behavior: 'smooth' });
		}
	}, []);

	return (
		<div className={`mx-auto flex flex-col justify-center py-4 ${isHomepage ? 'max-w-screen-2xl' : ''}`}>
			{loading ? (
				<div>
					<FontAwesomeIcon icon={faSpinnerThird} className="text-white" size="lg" spin />
				</div>
			) : (
				<>
					<div className="flex justify-between">
						<div className="text-2xl font-bold text-white">PPA Tour</div>
						<div className="flex gap-4">
							<div
								onClick={() => {
									scroll('left');
								}}
								className="prev-scroll z-10 flex size-10 cursor-pointer items-center justify-center rounded-full border border-blue-500 transition duration-150 hover:bg-blue-500 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-300 disabled:hover:bg-gray-100 lg:-left-6"
							>
								<FontAwesomeIcon icon={faArrowLeft} className="block text-white" />
							</div>
							<div
								onClick={() => {
									scroll('right');
								}}
								className="prev-scroll z-10 flex size-10 cursor-pointer items-center justify-center rounded-full border border-blue-500 transition duration-150 hover:bg-blue-500 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-300 disabled:hover:bg-gray-100 lg:-left-6"
							>
								<FontAwesomeIcon icon={faArrowRight} className="block text-white" />
							</div>
						</div>
					</div>
					<div className="mt-4 grid" style={{ gridTemplateColumns: '100%' }}>
						<div className="grow-1 relative min-w-full flex-1 shrink-0">
							<div
								className="grid"
								style={{
									gridTemplate: "var( --contentscroller_layout_grid-template, 'header' 'scroller' 'footer'/minmax(0,1fr) )"
								}}
							>
								<div
									className=""
									style={{
										gridArea: 'var(--contentscroller_scroller_grid-area,scroller)'
									}}
								>
									<div>
										<div style={{ aspectRatio: 'unset' }}>
											<div style={{ height: '100%', minWidth: 0, position: 'unset' }}>
												<div
													className="no-scrollbar"
													ref={containerRef}
													style={{
														margin: 0,
														gridAutoFlow: 'column',
														justifyContent: 'flex-start',
														scrollSnapType: 'none',
														gridTemplateAreas: 'var(--contentscroller_areas,none)',
														gridTemplateRows: 'var(--contentscroller_rows,none)',
														gridAutoColumns:
															'var( --contentscroller_auto-columns,calc((100% - var(--contentscroller_gap,16px) * (var(--contentscroller_visible-items,unset) - 1)) / var(--contentscroller_visible-items,unset)) )',
														display: 'grid',
														overflow: 'var(--contentscroller_overflow,auto hidden)',
														height: 'var(--contentscroller_height)',
														minHeight: 'var(--contentscroller_min-height)',
														gap: 0
													}}
												>
													<div className="inline-flex gap-6">
														{data?.ppatournaments?.items
															.filter((item: any) => item.status !== 'canceled')
															.map((tournament: any) => {
																return (
																	<FavoritePPATournamentCard
																		key={tournament.id}
																		moduleUuid={tournament.id}
																		{...tournament}
																		isTournamentsApp={true}
																		tournamentsAppBaseURL={`${process.env.NEXT_PUBLIC_PT_URL}`}
																	/>
																);
															})}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

/* {data?.ppatournaments?.items.map((item: any) => {
	return <div className="min-w-48 bg-error-300 py-10" key={item.id}></div>;
})} */
