'use client';

import { TournamentCardBadge, TournamentCardBadgeType, TournamentCardProps, renderPlayerTourneyCardLabels } from './TournamentCard';
import getCDNURL from '../../pickleball-app/utils/getCDNURL';
import getPBracketsCDNURL from '../../pickleball-app/utils/getPBracketsCDNURL';
import { useMemo } from 'react';
import ImageSlider from '../../ui/image-slider';
import CustomLink from '../../ui/custom-link';
import clsx from 'clsx';
import moment from 'moment';

export const MiniEventBadge = ({ children, type }: { children: React.ReactNode; type: TournamentCardBadgeType }) => {
	return (
		<TournamentCardBadge className="w-full rounded-b-none rounded-t-md" type={type} size="sm">
			{children}
		</TournamentCardBadge>
	);
};

export const PPATournamentCard = (props: TournamentCardProps) => {
	const { additionalImages, slug } = props;

	const newStatus = props.status ? props.status : renderPlayerTourneyCardLabels(props);

	return (
		<>
			{additionalImages && additionalImages?.length > 0 ? (
				<PPATournamentCardComponent {...props} status={newStatus} />
			) : (
				<CustomLink href={`${props.tournamentsAppBaseURL}/tournaments/${slug}`}>
					<PPATournamentCardComponent {...props} status={newStatus} />
				</CustomLink>
			)}
		</>
	);
};

export const PPATournamentCardComponent = ({
	dateFrom,
	dateTo,
	logo,
	location,
	slug,
	registrationCount,
	status,
	title,
	price,
	currency,
	isTournamentsApp,
	additionalImages,
	tournamentsAppBaseURL
}: TournamentCardProps) => {
	// Using parseZone() to show dates in Tourney's timezone
	const formattedFromDate = moment.parseZone(dateFrom).format('MMM D, YYYY');
	const formattedToDate = moment.parseZone(dateTo).format('MMM D, YYYY');

	const getImageToDisplay = useMemo(() => {
		if (logo && (logo.toLowerCase().includes('/uploads/clubs/') || logo.toLowerCase().includes('/uploads/events/'))) {
			return getPBracketsCDNURL(logo, 566, 384);
		}

		return getCDNURL('https://images.pickleball.com/pickleball-app/static/images/pickleball.png', 283, 192);
	}, [logo]);

	return (
		<div
			className={clsx(
				'group flex size-full select-none border rounded-xl border-gray-200',
				isTournamentsApp ? 'min-w-[215px] max-w-[215px]' : 'w-full min-[500px]:min-w-[224px] min-[500px]:max-w-[286px]'
			)}
		>
			<div className="flex size-full cursor-pointer flex-col">
				<div
					className={`relative flex items-center w-full justify-center overflow-hidden rounded-t-xl bg-white`}
					style={{ aspectRatio: '16 / 9' }}
				>
					{additionalImages && additionalImages.length > 0 ? (
						<ImageSlider
							customHref={`/tournaments/${slug}`}
							size="sm"
							containerClassNames="size-full"
							images={[getImageToDisplay, ...additionalImages]}
							objectCover
						/>
					) : (
						<img src={getImageToDisplay} alt={title} className={`block size-full rounded-t-md object-cover`} />
					)}
				</div>
				{additionalImages && additionalImages.length > 0 ? (
					<>
						<a
							href={`${tournamentsAppBaseURL}/tournaments/${slug}`}
							className={`flex flex-1 flex-col p-4 pt-3 rounded-b-xl bg-blue-900 ${isTournamentsApp ? 'group-hover:bg-blue-500' : 'group-hover:bg-gray-50'}`}
						>
							<div className="flex flex-1 flex-col gap-2">
								<p
									className={`line-clamp-2 ${
										isTournamentsApp ? '' : 'min-h-[2rem] group-hover:text-gray-900'
									} whitespace-pre-wrap text-sm font-medium leading-4 text-white`}
									style={{ minHeight: '2rem' }}
								>
									{title}
								</p>
								<p
									className={`!line-clamp-1 font-body flex items-center text-sm text-white ${isTournamentsApp ? '' : 'group-hover:text-gray-700'}`}
									style={{ minHeight: '1rem' }}
								>
									{location}
								</p>
								<p
									className={`!line-clamp-1 mb-1 text-xs text-blue-100 ${isTournamentsApp ? '' : 'group-hover:text-gray-700'}`}
								>{`${formattedFromDate} - ${formattedToDate}`}</p>
							</div>
							<div className="flex gap-2 flex-wrap items-center justify-between pt-2 text-sm text-gray-700 md:flex-row">
								{status === 'completed' && <TournamentCardBadge type="closed">Completed</TournamentCardBadge>}
								{status === 'registration-closed' && <TournamentCardBadge type="closed">Reg. Closed</TournamentCardBadge>}
								{status === 'price' && (
									<TournamentCardBadge type="default">
										<p
											className={`${isTournamentsApp ? 'text-xs font-medium' : 'text-sm'}`}
										>{`${currency ? currency.toUpperCase() : ''} ${new Intl.NumberFormat('en-US', {
											style: 'currency',
											currency,
											currencyDisplay: 'narrowSymbol'
										}).format(price)}`}</p>
									</TournamentCardBadge>
								)}
								{status === 'cost-per-event' && <TournamentCardBadge type="default">Cost Per Event</TournamentCardBadge>}
								{status === 'free' && <TournamentCardBadge type="default">Free</TournamentCardBadge>}
								<div className="my-0">
									<span
										className={`text-xs text-white ${isTournamentsApp ? '' : 'group-hover:text-gray-600'}`}
									>{`${registrationCount} Players`}</span>
								</div>
							</div>
						</a>
					</>
				) : (
					<>
						<div
							className={`flex flex-1 flex-col p-4 pt-3 rounded-b-xl bg-blue-900 ${isTournamentsApp ? 'group-hover:bg-blue-500' : 'group-hover:bg-gray-50'}`}
						>
							<div className="flex flex-1 flex-col gap-2">
								<p
									className={`line-clamp-2 ${
										isTournamentsApp ? '' : 'min-h-[2rem] group-hover:text-gray-900'
									} whitespace-pre-wrap text-sm font-medium leading-4 text-white`}
									style={{ minHeight: '2rem' }}
								>
									{title}
								</p>
								<p
									className={`!line-clamp-1 font-body flex items-center text-sm text-white ${isTournamentsApp ? '' : 'group-hover:text-gray-600'}`}
									style={{ minHeight: '1rem' }}
								>
									{location}
								</p>
								<p
									className={`!line-clamp-1 mb-1 text-xs text-blue-100 ${isTournamentsApp ? '' : 'group-hover:text-gray-700'}`}
								>{`${formattedFromDate} - ${formattedToDate}`}</p>
							</div>
							<div className="flex gap-2 flex-wrap items-center justify-between pt-2 text-sm text-gray-700 md:flex-row">
								{status === 'completed' && <TournamentCardBadge type="closed">Completed</TournamentCardBadge>}
								{status === 'registration-closed' && <TournamentCardBadge type="closed">Reg. Closed</TournamentCardBadge>}
								{status === 'price' && (
									<TournamentCardBadge type="default">
										<p
											className={`${isTournamentsApp ? 'text-xs font-medium' : 'text-sm'}`}
										>{`${currency ? currency.toUpperCase() : ''} ${new Intl.NumberFormat('en-US', {
											style: 'currency',
											currency,
											currencyDisplay: 'narrowSymbol'
										}).format(price)}`}</p>
									</TournamentCardBadge>
								)}
								{status === 'cost-per-event' && <TournamentCardBadge type="default">Cost Per Event</TournamentCardBadge>}
								{status === 'free' && <TournamentCardBadge type="default">Free</TournamentCardBadge>}
								<div className="my-0">
									<span
										className={`text-xs text-white ${isTournamentsApp ? '' : 'group-hover:text-gray-600'}`}
									>{`${registrationCount} Players`}</span>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
