import { gql } from '@apollo/client';

export const PPA_TOURNAMENT_QUERY = gql`
	query GetPPATournaments($page: Int, $requesterUuid: String) {
		ppatournaments(page: $page, requesterUuid: $requesterUuid) {
			items {
				id
				dateFrom
				dateTo
				currency
				location
				isCanceled
				isCostPerEvent
				isFree
				isPrizeMoney
				isRegistrationClosed
				isTournamentCompleted
				lat
				lng
				logo
				price
				registrationCount
				slug
				status
				title
				additionalImages
				hideRegisteredPlayers
				isAdvertiseOnly
				isFavorite
			}
			totalCount
		}
	}
`;
