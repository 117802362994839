'use client';

import { RouteResponse } from '@pickleballinc/lib/server/next/route/types';
import { useQuery } from '@tanstack/react-query';

import { UserUpcomingTournamentResponse } from '@/app/api/userUpcomingTournament/route';
import { useSession } from '@/lib/SessionProvider';
import { UPCOMING_TOURNAMENT_MODAL_KEY } from '@/utils/helpers/constants';

export const useGetUserUpcomingTournament = () => {
	const { session } = useSession();

	return useQuery({
		queryKey: ['userUpcomingTournament'],
		queryFn: async () => {
			const response = await fetch(
				`/api/userUpcomingTournament?ignoredTournamentsJSON=${localStorage.getItem(`${UPCOMING_TOURNAMENT_MODAL_KEY}_${session?.user?.uuid}`)}`
			);
			const result: RouteResponse<UserUpcomingTournamentResponse | null> = await response.json();
			return result.data;
		},
		enabled: !!session?.user?.token
	});
};
