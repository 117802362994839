'use client';

import CustomLink from '../../ui/custom-link';
import getCDNURL from '../../pickleball-app/utils/getCDNURL';
import getPBracketsCDNURL from '../../pickleball-app/utils/getPBracketsCDNURL';
import clsx from 'clsx';
import { useMemo } from 'react';
import ImageSlider from '../../ui/image-slider';
import moment from 'moment';

export function renderPlayerTourneyCardLabels(tournament: TournamentCardProps): string {
	if (tournament.isCanceled) {
		return 'canceled';
	}

	const tourneyEndDate = new Date(tournament.dateTo);
	const now = new Date();

	if (tourneyEndDate < now) {
		return 'completed';
	}

	if (tournament.isRegistrationClosed) {
		return 'registration-closed';
	}

	if (tournament.price > 0) {
		return 'price';
	}

	if (tournament.isCostPerEvent) {
		return 'cost-per-event';
	}

	return 'free';
}

export interface TournamentCardProps {
	id: string;
	dateFrom: string;
	dateTo: string;
	isCanceled?: boolean;
	isCostPerEvent?: boolean;
	currency: string;
	location: string;
	isRegistrationClosed: boolean;
	registrationDateClosed?: Date | undefined;
	logo: string;
	price: number;
	registrationCount: number;
	slug: string;
	status?: string;
	title: string;
	isTournamentsApp?: boolean;
	tournamentsAppBaseURL: string;
	additionalImages?: string[];
	customCardHref?: string;
	isAdvertiseOnly?: boolean;
	hideRegisteredPlayers?: boolean;
	className?: string;
	logoClassName?: string;
	isFavorite?: boolean;
}

export const TournamentCard = (props: TournamentCardProps) => {
	const { additionalImages, id, slug, customCardHref, tournamentsAppBaseURL } = props;

	const newStatus = props.status ? props.status : renderPlayerTourneyCardLabels(props);

	return (
		<>
			{additionalImages && additionalImages.length > 0 ? (
				<TournamentCardComponent {...props} status={newStatus} />
			) : customCardHref ? (
				<CustomLink href={customCardHref} className="contents">
					<TournamentCardComponent {...props} status={newStatus} />
				</CustomLink>
			) : (
				<CustomLink href={`${tournamentsAppBaseURL}/tournaments/${slug ? slug : id}`} className="contents">
					<TournamentCardComponent {...props} status={newStatus} />
				</CustomLink>
			)}
		</>
	);
};

export const TournamentCardComponent = ({
	id,
	dateFrom,
	dateTo,
	logo,
	currency,
	location,
	price,
	slug,
	registrationCount,
	registrationDateClosed,
	status,
	title,
	additionalImages,
	hideRegisteredPlayers,
	isAdvertiseOnly,
	className,
	logoClassName,
	tournamentsAppBaseURL
}: TournamentCardProps) => {
	const tourneyFromDate = new Date(dateFrom);
	const tourneyToDate = new Date(dateTo);
	const currentDate = new Date();

	// Using parseZone() to show dates in Tourney's timezone
	const formattedFromDate = moment.parseZone(dateFrom).format('MMM D, YYYY');
	const formattedToDate = moment.parseZone(dateTo).format('MMM D, YYYY');

	const isPlayingNow = currentDate >= tourneyFromDate && currentDate <= tourneyToDate && status != 'completed';

	const isLessThan48Hours =
		registrationDateClosed && registrationDateClosed instanceof Date
			? registrationDateClosed.getTime() - currentDate.getTime() >= 0 &&
				registrationDateClosed.getTime() - currentDate.getTime() <= 48 * 60 * 60 * 1000
			: false;
	const getImageToDisplay = useMemo(() => {
		if (logo && (logo.toLowerCase().includes('/uploads/clubs/') || logo.toLowerCase().includes('/uploads/events/'))) {
			return getPBracketsCDNURL(logo, 566, 384);
		}

		return getCDNURL('https://images.pickleball.com/pickleball-app/static/images/pickleball.png', 566, 384);
	}, [logo]);

	return (
		<div
			className={clsx(
				className,
				'group relative flex cursor-pointer !size-full min-[500px]:min-w-[224px] min-[500px]:max-w-[286px] flex-col rounded-xl border bg-white'
			)}
		>
			{isLessThan48Hours && status !== 'closed' && (
				<div className="absolute top-2 left-2 z-30 px-2 py-1 text-sm rounded-md bg-success-700 text-white">Closing Soon</div>
			)}

			<div
				className={clsx(
					logoClassName,
					'relative flex items-center w-full justify-center overflow-hidden rounded-t-xl border-b border-b-gray-200 bg-white'
				)}
				style={{ aspectRatio: '16 / 9' }}
			>
				{additionalImages && additionalImages.length > 0 ? (
					<ImageSlider
						customHref={`/tournaments/${slug}`}
						size="sm"
						containerClassNames="h-full"
						images={[getImageToDisplay, ...additionalImages]}
						objectCover
					/>
				) : (
					<img src={getImageToDisplay} alt={title} className={clsx(logoClassName, 'block h-full rounded-t-xl object-cover')} />
				)}
			</div>
			{additionalImages && additionalImages.length > 0 ? (
				<CustomLink href={`${tournamentsAppBaseURL}/tournaments/${slug ? slug : id}`} className="contents">
					<div className="flex flex-1 flex-col rounded-b-xl justify-between gap-2 p-4 pt-3 relative z-20 group-hover:bg-gray-50">
						<div className="!line-clamp-2 block text-sm font-medium text-gray-900" title={title} style={{ minHeight: '2.5rem' }}>
							{title}
						</div>

						{location && (
							<div className="line-clamp-1 text-sm text-gray-600" style={{ minHeight: '1rem' }}>
								{location}
							</div>
						)}
						{(dateFrom || dateTo) && (
							<div className="text-sm line-clamp-1 text-gray-700" style={{ minHeight: '1.25rem' }}>
								{`${formattedFromDate} - ${formattedToDate}`}
							</div>
						)}
						{(status || registrationCount) && (
							<div className="mt-2 flex flex-row flex-wrap items-center justify-between gap-1">
								{isPlayingNow && (
									<TournamentCardBadge type="default">
										<p className="text-sm font-semibold">Playing Now</p>
									</TournamentCardBadge>
								)}

								{status === 'completed' && (
									<TournamentCardBadge type="closed">
										<p className="text-sm font-semibold">Completed</p>
									</TournamentCardBadge>
								)}
								{status === 'registration-closed' && !isPlayingNow && (
									<TournamentCardBadge type="closed">
										<p className="text-sm font-semibold">Reg. Closed</p>
									</TournamentCardBadge>
								)}
								{status === 'price' && !isPlayingNow && (
									<TournamentCardBadge type="default">
										<p className="text-sm font-semibold">{`${currency ? currency.toUpperCase() : ''} ${new Intl.NumberFormat('en-US', { style: 'currency', currency: currency, currencyDisplay: 'narrowSymbol' }).format(price)}`}</p>
									</TournamentCardBadge>
								)}
								{status === 'cost-per-event' && !isPlayingNow && (
									<TournamentCardBadge type="default">
										<p className="text-sm font-semibold">Cost Per Event</p>
									</TournamentCardBadge>
								)}
								{status === 'free' && !isPlayingNow && (
									<TournamentCardBadge type="default">
										<p className="text-sm font-semibold">Free</p>
									</TournamentCardBadge>
								)}
								{!hideRegisteredPlayers && (
									<>
										{isAdvertiseOnly ? (
											<div className="text-xs flex text-gray-600 items-center" style={{ minHeight: '1.75rem' }}>
												Advertised
											</div>
										) : (
											<div
												className="text-xs flex text-gray-600 items-center"
												style={{ minHeight: '1.75rem' }}
											>{`${registrationCount} player${registrationCount != 1 ? 's' : ''}`}</div>
										)}
									</>
								)}
							</div>
						)}
					</div>
				</CustomLink>
			) : (
				<div className="flex flex-1 flex-col rounded-b-xl justify-between gap-2 p-4 pt-3 relative z-20 group-hover:bg-gray-50">
					<div className="!line-clamp-2 block text-sm font-medium text-gray-900" title={title} style={{ minHeight: '2.5rem' }}>
						{title}
					</div>

					{location && (
						<div className="line-clamp-1 text-sm text-gray-600" style={{ minHeight: '1.25rem' }}>
							{location}
						</div>
					)}
					{(dateFrom || dateTo) && (
						<div className="text-sm line-clamp-1 text-gray-700" style={{ minHeight: '1.25rem' }}>
							{`${formattedFromDate} - ${formattedToDate}`}
						</div>
					)}
					{(status || registrationCount) && (
						<div className="mt-2 flex flex-row flex-wrap items-center justify-between gap-1">
							{isPlayingNow && (
								<TournamentCardBadge type="default">
									<p className="text-sm font-semibold">Playing Now</p>
								</TournamentCardBadge>
							)}
							{status === 'completed' && (
								<TournamentCardBadge type="closed">
									<p className="text-sm font-semibold">Completed</p>
								</TournamentCardBadge>
							)}
							{status === 'registration-closed' && !isPlayingNow && (
								<TournamentCardBadge type="closed">
									<p className="text-sm font-semibold">Reg. Closed</p>
								</TournamentCardBadge>
							)}
							{status === 'price' && !isPlayingNow && (
								<TournamentCardBadge type="default">
									<p className="text-sm font-semibold">{`${currency ? currency.toUpperCase() : ''} ${new Intl.NumberFormat('en-US', { style: 'currency', currency: currency, currencyDisplay: 'narrowSymbol' }).format(price)}`}</p>
								</TournamentCardBadge>
							)}
							{status === 'cost-per-event' && !isPlayingNow && (
								<TournamentCardBadge type="default">
									<p className="text-sm font-semibold">Cost Per Event</p>
								</TournamentCardBadge>
							)}
							{status === 'free' && !isPlayingNow && (
								<TournamentCardBadge type="default">
									<p className="text-sm font-semibold">Free</p>
								</TournamentCardBadge>
							)}
							{!hideRegisteredPlayers && (
								<>
									{isAdvertiseOnly ? (
										<div className="text-xs flex text-gray-600 items-center" style={{ minHeight: '1.75rem' }}>
											Advertised
										</div>
									) : (
										<div
											className="text-xs flex text-gray-600 items-center"
											style={{ minHeight: '1.75rem' }}
										>{`${registrationCount} player${registrationCount != 1 ? 's' : ''}`}</div>
									)}
								</>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export type TournamentCardBadgeType = 'default' | 'closed';

export const TournamentCardBadge = ({
	children,
	className,
	type
}: {
	children: React.ReactNode;
	className?: string;
	type: TournamentCardBadgeType;
	size?: 'sm' | 'md';
}) => {
	const classnames = clsx(
		'px-2 py-1 text-center text-xs font-semibold rounded-md',
		{
			'bg-success-700 text-white': type === 'default',
			'bg-gray-700 text-white': type === 'closed'
		},
		className
	);

	return <div className={classnames}>{children}</div>;
};
