'use client';

import { faArrowRight, faCalendar, faLocationDot, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Modal, ModalClose, ModalContent, ModalHeading } from '@pickleballinc/react-ui';
import getCDNURL from '@pickleballinc/ui/components/pickleball-app/utils/getCDNURL';
import CustomLink from '@pickleballinc/ui/components/ui/custom-link';
import { useEffect, useState } from 'react';

import { useSession } from '@/lib/SessionProvider';
import { useGetUserUpcomingTournament } from '@/tournament/hooks/useGetUserUpcomingTournament';
import { UPCOMING_TOURNAMENT_MODAL_KEY } from '@/utils/helpers/constants';

const UpcomingTournamentModal = () => {
	const { data } = useGetUserUpcomingTournament();

	const [modalOpened, setModalOpened] = useState(false);
	const [dontShowModal, setDontShowModal] = useState(false);

	const { session } = useSession();

	const saveIgnoredTournamentModal = (tourneyId: string): void => {
		const ignored = getIgnoredTournamentModals();
		if (!ignored.includes(tourneyId)) {
			ignored.push(tourneyId);
			localStorage.setItem(`${UPCOMING_TOURNAMENT_MODAL_KEY}_${session?.user?.uuid}`, JSON.stringify(ignored));
		}
	};

	const removeIgnoredTournamentModal = (tourneyId: string): void => {
		const ignoredEvents = getIgnoredTournamentModals().filter((id) => id !== tourneyId);
		localStorage.setItem(`${UPCOMING_TOURNAMENT_MODAL_KEY}_${session?.user?.uuid}`, JSON.stringify(ignoredEvents));
	};

	const getIgnoredTournamentModals = (): string[] => {
		const data = localStorage.getItem(`${UPCOMING_TOURNAMENT_MODAL_KEY}_${session?.user?.uuid}`);
		return data ? JSON.parse(data) : [];
	};

	const currentMatchURL = `https://brackets.${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/tournaments/${data?.slug}/events/${data?.eventId}/player-view/current-match`;

	const isTournamentModalIgnored = (tourneyId: string): boolean => {
		return getIgnoredTournamentModals().includes(tourneyId);
	};

	useEffect(() => {
		setModalOpened(!!data && !isTournamentModalIgnored(data.tourneyId));
	}, [data]);

	useEffect(() => {
		if (dontShowModal) {
			saveIgnoredTournamentModal(data?.tourneyId || '');
		} else {
			removeIgnoredTournamentModal(data?.tourneyId || '');
		}
	}, [dontShowModal]);

	return (
		<Modal backdropClose={false} open={modalOpened} onOpenChange={() => setModalOpened(false)}>
			<ModalContent className="flex w-[calc(100%-16px)] max-w-[480px] flex-col gap-4 !overflow-hidden !p-4 !outline-none">
				<ModalHeading className="flex w-full items-center justify-between">
					<div className="text-gray-700 font-semibold">You&apos;re scheduled to play in this tournament!</div>
					<ModalClose className="ml-auto">
						<FontAwesomeIcon
							icon={faTimes}
							size="lg"
							className="text-gray-700 hover:bg-gray-200 bg-gray-100 rounded-full !w-[20px] !h-[20px] p-1"
						/>
					</ModalClose>
				</ModalHeading>

				<div className="flex items-center justify-center gap-4">
					<img
						src={getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleballtournaments-app/pt-logo-dark-blue.svg`, 56, 40)}
						alt="Pickleballtournaments.com"
						width={56}
						height={40}
					/>
				</div>
				<div className="bg-[#EAF0FF] p-2 rounded-lg">
					<div className="text-gray-900 font-medium">Tournament</div>
					<div className="text-gray-700">{data?.title}</div>
				</div>

				<div className="p-2 flex flex-col gap-4">
					<div className="flex flex-row gap-2">
						<div>
							<FontAwesomeIcon icon={faCalendar} />
						</div>
						<div>
							<div className="text-gray-900 font-medium">Registration opens</div>
							<div className="text-gray-700">{data?.regOpenDate}</div>
							<div className="text-gray-700 text-sm">{data?.regOpenTime}</div>
						</div>
					</div>
					<div className="flex flex-row gap-2">
						<div>
							<FontAwesomeIcon icon={faLocationDot} />
						</div>
						<div>
							<div className="text-gray-900 font-medium">{data?.venueName}</div>
							<div className="text-gray-700">{data?.cityStateZip}</div>
							<div className="text-gray-700">{data?.street}</div>
							<a
								target="_blank"
								href={`https://www.google.com/maps?q=${data?.venueName}, ${data?.street}, ${data?.cityStateZip}`}
								aria-label="Link to get directions (opens in a new tab)"
							>
								<Button size="sm" variant="link" className="!text-blue-500 hover:underline !font-medium text-sm">
									Get Directions
								</Button>
							</a>
						</div>
					</div>
				</div>

				<div className="flex flex-col items-center gap-4">
					{data?.isCurrentMatch ? (
						<CustomLink href={currentMatchURL} className="w-full">
							<Button
								suffixIcon={<FontAwesomeIcon icon={faArrowRight} />}
								variant="blue"
								className="flex justify-center text-sm w-full"
							>
								View Current Match
							</Button>
						</CustomLink>
					) : (
						<CustomLink href={`/tournaments/${data?.slug || data?.tourneyId}`} className="w-full">
							<Button
								suffixIcon={<FontAwesomeIcon icon={faArrowRight} />}
								variant="blue"
								className="flex justify-center text-sm w-full"
							>
								Go to this Tournament
							</Button>
						</CustomLink>
					)}

					<div className="flex flex-row">
						<Checkbox
							checked={dontShowModal}
							onChange={() => setDontShowModal(!dontShowModal)}
							className="checked:border-blue-400 checked:bg-blue-100 hover:border-blue-400 hover:bg-blue-100 focus:border-blue-400 focus:bg-blue-100 focus:!shadow-blue-100 active:!border-blue-400 active:!bg-blue-100 active:!shadow-blue-100"
							name="Disable upcoming tournament modal"
							size="sm"
						/>
						<Button
							onClick={() => setDontShowModal(!dontShowModal)}
							variant="link"
							className="!text-blue-500 underlined !font-medium text-sm"
						>
							Don&apos;t show again for this tournament
						</Button>
					</div>
				</div>
			</ModalContent>
		</Modal>
	);
};

export default UpcomingTournamentModal;
