import { TournamentCard, TournamentCardProps } from '@pickleballinc/ui/components/tournaments-app/Cards/TournamentCard';
import React, { useState } from 'react';

import FavoriteButton from '@/components/Favorite/FavoriteButton';
import { useSession } from '@/lib/SessionProvider';

interface FavoriteTournamentCardProps extends TournamentCardProps {
	moduleUuid: string;
}

const FavoriteTournamentCard = ({ moduleUuid, ...tournamentProps }: FavoriteTournamentCardProps) => {
	const [isFavorited, setIsFavorited] = useState(tournamentProps.isFavorite || false);
	const { session } = useSession();

	return (
		<div className="relative !size-full min-[500px]:min-w-[224px] min-[500px]:max-w-[286px]">
			{process.env.NEXT_PUBLIC_APP_ENV !== 'prod' && session?.user?.uuid && (
				<div className="absolute right-2 top-2 z-10">
					<FavoriteButton moduleUuid={moduleUuid} isFavorited={isFavorited} setIsFavorited={setIsFavorited} />
				</div>
			)}
			<TournamentCard {...tournamentProps} />
		</div>
	);
};

export default FavoriteTournamentCard;
