import { faHeart as faHeartRegular } from '@fortawesome/pro-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useCallback, useState } from 'react';

import { TOURNAMENT_FAVORITES_MODULE_FOR } from '@/blog/constants';
import { addToFavorites } from '@/utils/actions/blog/addToFavorites';
import { removeFavorites } from '@/utils/actions/blog/removeFavorites';

interface FavoriteButtonProps {
	moduleUuid: string;
	isFavorited: boolean;
	setIsFavorited: React.Dispatch<React.SetStateAction<boolean>>;
	isDetailsPage?: boolean;
}

const FavoriteButton = ({ moduleUuid, isFavorited, setIsFavorited, isDetailsPage }: FavoriteButtonProps) => {
	const [localIsFavorited, setLocalIsFavorited] = useState(isFavorited);
	const [error, setError] = useState<string | null>(null);
	const [isDisabled, setIsDisabled] = useState(false);

	const handleFavoriteAction = useCallback(async () => {
		if (isDisabled) return;

		setError(null);
		setIsDisabled(true);

		const newFavoritedState = !localIsFavorited;

		setLocalIsFavorited(newFavoritedState);
		setIsFavorited(newFavoritedState);

		try {
			if (newFavoritedState) {
				await addToFavorites({
					moduleFor: TOURNAMENT_FAVORITES_MODULE_FOR,
					moduleUuid
				});
			} else {
				await removeFavorites({
					moduleFor: TOURNAMENT_FAVORITES_MODULE_FOR,
					moduleUuid
				});
			}
		} catch (err) {
			setError('Action failed. Please try again.');
			setLocalIsFavorited(!newFavoritedState);
			setIsFavorited(!newFavoritedState);
			console.error('Error updating favorites:', err);
		} finally {
			setIsDisabled(false);
		}
	}, [isDisabled, localIsFavorited, moduleUuid, setIsFavorited]);

	return (
		<>
			<button
				onClick={(event) => {
					event.stopPropagation();
					handleFavoriteAction();
				}}
				disabled={isDisabled}
				className={clsx('max-h-[34px] flex items-center justify-center rounded-md border border-gray-300 p-2 transition-all duration-200', {
					'opacity-50 cursor-not-allowed': isDisabled,
					'hover:bg-gray-100 bg-white': !isDisabled,
					'max-w-[34px]': !isDetailsPage,
					'text-sm py-1 px-2': isDetailsPage
				})}
			>
				<div className="flex flex-row items-center justify-center gap-2">
					{isDetailsPage &&
						(!localIsFavorited ? <p className="text-gray-700">Add to favorites</p> : <p className="text-gray-700">Favorited</p>)}
					<FontAwesomeIcon
						icon={localIsFavorited ? faHeartSolid : faHeartRegular}
						size="lg"
						className={clsx(localIsFavorited ? (isDetailsPage ? 'text-blue-500' : 'text-error-500') : 'text-gray-700', {
							'animate-bounce': isDisabled
						})}
					/>
				</div>
			</button>
			{error && <p className="mt-1 text-sm text-error-500">{error}</p>}
		</>
	);
};

export default FavoriteButton;
